.video-container {
  position: relative;
  display: inline-block;
}

.draggable {
  position: absolute;
  cursor: move;
}

.draggable.normal {
  border: 5px solid red;
}

.draggable.crop {
  border: 5px solid blue;
  z-index: 0;
}

.draggable.name {
  border: 5px solid yellow;
}

.draggable.subtitle {
  border: 5px solid green;
}

.resizable {
  resize: both;
  overflow: auto;
}

.resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: 0;
  right: 0;
  background-color: blue;
  cursor: se-resize;
}